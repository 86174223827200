<template>
  <PaymentModal
    v-if="modalVisibility"
    @close="closeModal"
    @submit="submitOrder"
  />

  <div class="layout-padding">
    <PageTitle
      class="my-8"
      :title="content.checkout"
      :childOne="content.giftSubscription"
      :childTwo="content.checkout"
    />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8">
        <!-- <div class="order-card">
          <label>
            <input
              type="radio"
              :value="pickup ? false : true"
              :checked="pickup ? true : false"
              @change="(event) => setPickup(event)"
            />
            <span class="ms-2"> {{ content.pickup }} </span>
          </label>
          <div
            v-if="pickup"
            class="grid grid-cols-2 gap-x-10 gap-y-5 w-full mt-5 ps-5"
          >
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="city"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.city }}
                </label>
                <select id="city" class="form-input bg-white" v-model="cityId">
                  <option disabled selected :value="null">City</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="point"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.location }}
                </label>
                <select
                  id="point"
                  class="form-input bg-white"
                  v-model="sellingPoint"
                >
                  <option
                    v-if="sellingPoints.length < 1"
                    disabled
                    selected
                    :value="null"
                  >
                    {{ content.noDrop }}
                  </option>
                  <option v-else disabled selected :value="null">
                    {{ content.location }}
                  </option>
                  <option
                    v-for="location in sellingPoints"
                    :key="location.id"
                    :value="location.id"
                  >
                    {{ location.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-2">
              <div class="flex flex-col sm:flex-row mt-8">
                <label
                  for="notes"
                  class="input-label mb-2 sm:mb-0 flex items-start"
                >
                  {{ content.notes }}
                </label>
                <textarea
                  type="text"
                  class="form-input"
                  v-model="notes"
                  id="notes"
                  :placeholder="content.notes"
                />
              </div>
            </div>
          </div>
        </div> -->
        <div class="order-card">
          <label>
            <!-- <input
              type="radio"
              :value="delivery ? false : true"
              :checked="delivery ? true : false"
              @change="(event) => setDelivery(event)"
            />
            <span class="ms-2"> {{ content.delivery }} </span> -->
            <span class="ms-5"> {{ content.delivery }} </span>
          </label>
          <div
            v-if="delivery"
            class="grid grid-cols-2 gap-x-10 gap-y-5 w-full mt-5 ps-5"
          >
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="city"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.city }}
                </label>
                <div class="relative w-full">
                  <select
                    id="city"
                    class="form-input bg-white"
                    v-model="cityId"
                  >
                    <option disabled selected :value="null">
                      {{ content.city }}
                    </option>
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <span class="error absolute left-0 -bottom-7">
                    {{ cityError }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="address"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.address }}
                </label>
                <div class="relative w-full">
                  <input
                    type="text"
                    class="form-input"
                    v-model="address"
                    id="address"
                    :placeholder="content.address"
                  />
                  <span class="error absolute left-0 -bottom-7">
                    {{ deliveryError }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div class="relative mt-8">
                <label
                  for="notes"
                  class="block text-sm font-medium text-gray-700 mb-2"
                >
                  {{ content.notes }}
                </label>
                <div class="relative">
                  <textarea
                    id="notes"
                    v-model="notes"
                    rows="4"
                    class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 resize-none"
                    :placeholder="content.notesPlaceholder || 'Add any special instructions or notes here...'"
                  ></textarea>
                  <div class="absolute bottom-3 right-3 text-sm text-gray-500">
                    {{ notes.length }}/500
                  </div>
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  {{ content.notesHelper || 'Add any special instructions, delivery preferences, or additional information.' }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="option" class="col-span-12 lg:col-span-8 mb-4">
          <Alert type="info" :message="content.chooseShipping"> </Alert>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery
          :delivery="city?.price"
          :button-disabled="buttonDisabled"
          :option="option"
          @submitOrder="openModal"
        />
      </div>
    </div>
    <div v-if="error" @click="closeFeedback" class="fixed"></div>
    <div class="w-full flex justify-center">
      <Alert
        v-if="error"
        customClasses="max-w-550"
        type="fail"
        :title="content.fail"
        :message="content.subscriptionFail"
      >
        <router-link
          to="/"
          class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-secondaryColor duration-300 mt-6"
        >
          {{ content.goHome }}
        </router-link>
      </Alert>
    </div>
  </div>
</template>

<script>
import OrderSummery from "./OrderSummary.vue";
// import CustomCheckbox from "/src/components/ui/CustomCheckbox.vue";

import PaymentModal from "../../../components/ui/PaymentModal.vue";

export default {
  components: {
    OrderSummery,
    PaymentModal,
    // CustomCheckbox,
  },
  inject: ["content"],
  data() {
    return {
      modalVisibility: false,
      pickup: false,
      // delivery: false,
      delivery: true,
      notes: "",
      maxNotesLength: 500,
      address: "",
      cityId: null,
      sellingPoint: null,
      option: null,
      error: null,
      cityError: null,
      deliveryError: null,
      buttonDisabled: false,
    };
  },
  // mounted() {
  // },
  watch: {
    city() {
      if (this.city) {
        this.cityError = null;
      }
    },
    address() {
      if (this.address && this.address != "") {
        this.deliveryError = null;
      }
    },
    notes(newValue) {
      // Trim notes if they exceed max length
      if (newValue.length > this.maxNotesLength) {
        this.notes = newValue.slice(0, this.maxNotesLength);
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    studio() {
      return this.$store.getters["shops/studio"];
    },
    sellingPoints() {
      let locations = [
        ...this.$store.getters["shops/sellingPoints"],
        this.studio,
      ];
      return this.city
        ? locations.filter(
            (location) => location.city.id == this.cityId && location.drop
          )
        : [];
    },
    selectedPackage() {
      return this.$store.getters["subscription/package"](this.$route.query.p);
    },
    plan() {
      return this.selectedPackage?.prices.filter(
        (plan) => plan.id == this.$route.query.m
      )[0];
    },
    gift() {
      return this.$route.query.g == 1;
    },
    product() {
      return this.$route.query.product
        ? this.$store.getters["products/product"]
        : null;
    },
    cities() {
      return this.$store.getters["cities/cities"];
    },
    city() {
      return this.cityId
        ? this.$store.getters["cities/city"](this.cityId)
        : null;
    },
    notesRemaining() {
      return this.maxNotesLength - this.notes.length;
    }
  },
  methods: {
    closeModal() {
      this.modalVisibility = false;
    },
    openModal() {
      if (!this.delivery && !this.pickup) {
        this.option = true;
        return;
      }

      if (this.delivery) {
        if (!this.city) {
          this.cityError = this.content.chooseCity;
          return;
        }
        if (!this.address) {
          this.deliveryError = this.content.enterAddress;
          return;
        }
      }

      this.modalVisibility = true;
    },
    setAddress() {
      this.accountAddress = !this.accountAddress;
    },
    setDelivery(event) {
      if (!this.cityId) this.cityId = this.user?.city?.id ?? null;
      this.delivery = event.target.value;
      if (this.pickup == this.delivery) this.pickup = !this.pickup;
      this.option = false;
    },
    setPickup(event) {
      if (!this.cityId) this.cityId = this.user?.city?.id ?? null;
      this.pickup = event.target.value;
      if (this.delivery == this.pickup) this.delivery = !this.delivery;
      this.option = false;
    },
    async submitOrder(method) {
      this.closeModal();
      this.buttonDisabled = true;

      let input = {
        method: method,
        id: this.$route.query.m,
        gift: this.$route.query.g == 1,
      };

      if (this.delivery) {
        input.city = this.cityId;
        input.address = this.address;
        input.sellingPoint = null;
      } else if (this.pickup) {
        input.sellingPoint = this.sellingPoint;
        input.city = null;
        input.address = null;
      }

      if (this.notes != "") input.notes = this.notes;

      try {
        // this.closeModal();

        await this.$store.dispatch("subscription/subscribe", input);
        await this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.requestSuccess,
        });
        if (method !== "fib") {
          this.$router.replace("/");
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.subscriptionFail,
        });
        // this.error = true;
      }
    },
    // toggleContact(event, id) {
    //   let item = this.contact.filter((item) => item.id == id)[0];
    //   item.active = event.target.value;
    //   // this.social[index].active = event.target.value;
    // },
  },
};
</script>

<style scoped>
.textarea-container {
  position: relative;
}

textarea {
  font-size: 14px;
  line-height: 1.5;
  min-height: 100px;
}

textarea:focus {
  outline: none;
}

/* Optional: Add a subtle hover effect */
textarea:hover {
  border-color: #a0aec0;
}

/* Optional: Style for disabled state */
textarea:disabled {
  background-color: #f7fafc;
  cursor: not-allowed;
}

/* Optional: Custom scrollbar for better aesthetics */
textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
</style>
